import { faSparkles } from "@fortawesome/pro-light-svg-icons";
import { Typography, TypographyProps } from "@mui/material";
import { Box } from "@mui/system";
import SquareFontAwesomeIcon from "../../util/SquareFontAwesomeIcon";

export default function TeamStatus(props: TypographyProps) {
    return <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" component="span" color="primary" {...props}>NextGlobe Team</Typography>
        <SquareFontAwesomeIcon icon={faSparkles} sx={{ ml: 0.8, color: "primary.main" }} />
    </Box>
}