import { Typography, Box, Link } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import usePlayer from "../../../swr/usePlayer";
import CardSettingsContent from "./CardSettingsContent";
import PlayerProfile from "../../player/PlayerProfile";
import ProfileLoginButton from "../../player/LoginButton";
import Card from "../../card/Card";
import CardTitle from "../../card/CardTitle";
import useSessionToken from "../../../hooks/sessiontoken/useSessionToken";
import ManageAccountButton from "../../player/ManageAccountButton";
import useMutateSessionToken from "../../../hooks/sessiontoken/useMutateSessionToken";

interface AccountCardProps {

}

export default function AccountCard(props: AccountCardProps) {
    const { t } = useTranslation();

    const sessionToken = useSessionToken();
    const mutateSessionToken = useMutateSessionToken();

    const { player, error, mutate } = usePlayer(sessionToken);

    return <Card>
        <Box sx={{ pb: "12px" }}>
            <CardTitle variant="h4" sx={{ pb: 0 }}>{t("landing:account")}</CardTitle>
            {!player?.userId && <Typography variant="subtitle1" component="span">{player ? t("landing:notLoggedIn") : "Logging in..."}</Typography>}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", mt: "-4px", mb: "12px", ml: -2 }}>
            <PlayerProfile player={player} viewer={player} error={!!error} mutate={mutate} wrap sx={{ ml: 2, flexGrow: 10000 }} />
            <Box sx={{ display: "flex", alignItems: "center", ml: 2, flexGrow: 1 }}>
                {player && (player.userId ? <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mt: 2, mb: -1 }}>
                    <ManageAccountButton />
                    <Link variant="body2" underline="hover" sx={{ mt: 1, cursor: "pointer" }} onClick={() => {
                        mutateSessionToken(null);
                        window.location.href = process.env.apiUrl + "/logout";
                    }}>Logout</Link>
                </Box> : <ProfileLoginButton size="big" defaultLoginAction sx={{ flexGrow: 1 }} />)}
            </Box>
        </Box>
        <Typography variant="h6" component="p" sx={{ pb: "12px" }}>{t("landing:settings")}</Typography>
        <CardSettingsContent player={player} />
    </Card>
}