import { Grid, Box, Collapse, Link } from "@mui/material";
import CreateRoomCard from "./cards/CreateRoomCard";
import AccountCard from "./cards/AccountCard";
import ChangelogCard from "./cards/ChangelogCard";
import FAQCard from "./cards/FAQCard";
import { useEffect, useState } from "react";
import { Alert } from '@mui/material';
import AdSenseAd from "../AdSenseAd";
import JoinRoomCard from "./cards/JoinRoomCard";
import SettingsCard from "./cards/SettingsCard";
import usePlayer from "../../swr/usePlayer";
import useSessionToken from "../../hooks/sessiontoken/useSessionToken";
import { RoomData } from "../../types/room/RoomData";
import AdinPlayAd from "../AdinPlayAd";
import useHighestRole, { Role } from "../../hooks/use-highest-role";
import {useShowAds} from "../../hooks/use-show-ads";

type LandingContentProps = {
    roomCardType: "create",
    roomData?: RoomData
} | {
    roomCardType: "join",
    roomData: RoomData
}

export default function LandingContent(props: LandingContentProps) {
    const { roomCardType, roomData } = props;

    const sessionToken = useSessionToken();

    const { error: playerError } = usePlayer(sessionToken);

    const showAds = useShowAds();

    const [displayNoConnectionWarning, setDisplayNoConnectionWarning] = useState(false);

    useEffect(() => {
        if (playerError) {
            const t = setTimeout(() => {
                setDisplayNoConnectionWarning(true);
            }, 10000);

            return function cleanup() {
                clearTimeout(t);
            }
        } else {
            setDisplayNoConnectionWarning(false);
        }
    }, [playerError, setDisplayNoConnectionWarning])

    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {displayNoConnectionWarning && <Box sx={{ display: "flex", justifyContent: "center", width: "100%", flexGrow: 1, mx: 2 }}>
                <Collapse in={true} collapsedSize={0} appear={true}>
                    <Alert severity="warning" sx={{ maxWidth: "1200px", mt: 2, mb: 3 }}>It seems that a connection to our backend can&apos;t be established. Either your internet connection is very slow, or we are currently experiencing problems on our side. Check out our <Link href="https://status.nextglobe.net/" target="_blank">status page</Link> for more info.</Alert>
                </Collapse>
            </Box>}
            <Box sx={{ maxWidth: "100%", px: "3vw", flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <Box sx={{ maxWidth: "1600px", flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {roomCardType === "create" ? <CreateRoomCard />
                                : roomCardType === "join" ? <JoinRoomCard roomData={roomData} />
                                    : null}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {roomCardType === "create" ? <AccountCard />
                                : roomCardType === "join" ? <SettingsCard /> : null}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* <Box my={"32px"} width="100%" mx={"5vw"}>
                <Divider/>
            </Box> */}
            <Box sx={{ justifyContent: "center", width: "100%", mx: "5vw", my: "32px", flexGrow: 1 }}>
                {showAds && <AdinPlayAd placementId="werewolf-online-io_970x250_2" />}
                {/* Ezoic - under_first_paragraph - under_first_paragraph */}
                <div id="ezoic-pub-ad-placeholder-113" />
                {/* End Ezoic - under_first_paragraph - under_first_paragraph */}
                <AdSenseAd slot="5692888536" />
            </Box>
            {/* <Box my={"32px"} width="100%" mx={"5vw"}>
                <Divider/>
            </Box> */}
            <Box sx={{ maxWidth: "100%", px: "3vw" }}>
                <Box sx={{ maxWidth: "1600px" }}>
                    <ChangelogCard />
                </Box>
            </Box>
            {/* <Box mt={"32px"} width="100%" mx={"5vw"}>
                <Divider/>
            </Box> */}
            <Box sx={{ justifyContent: "center", width: "100%", mx: "5vw", my: "16px", flexGrow: 1 }}>
                {/* Ezoic - under_second_paragraph - under_second_paragraph */}
                <div id="ezoic-pub-ad-placeholder-114" />
                {/* End Ezoic - under_second_paragraph - under_second_paragraph */}
            </Box>
            <Box sx={{ maxWidth: "100%", px: "3vw" }}>
                <Box sx={{ maxWidth: "1600px" }}>
                    <FAQCard />
                </Box>
            </Box>
            <Box sx={{ justifyContent: "center", width: "100%", mx: "5vw", my: "16px", flexGrow: 1 }}>
                {/* Ezoic - bottom_of_page - bottom_of_page */}
                <div id="ezoic-pub-ad-placeholder-103" />
                {/* End Ezoic - bottom_of_page - bottom_of_page */}
            </Box>
        </Box>
    );
}