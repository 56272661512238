import { Button as MuiButton, SxProps } from "@mui/material";
import { useCallback } from "react";
import useMutateSessionToken from "../../hooks/sessiontoken/useMutateSessionToken";
import Button from "../Button";

interface ProfileLoginButtonProps {
    size?: "big" | "small"
    onClick?: () => void
    /**
     * onClick overrides this
     */
    defaultLoginAction?: boolean
    sx?: SxProps
}

export default function ProfileLoginButton(props: ProfileLoginButtonProps) {
    const { size, onClick, defaultLoginAction, sx } = props;

    const mutateSessionToken = useMutateSessionToken();

    const onLogin = useCallback(() => {
        mutateSessionToken(null);
        window.location.href = process.env.apiUrl + "/login";
    }, [mutateSessionToken]);

    if (size === "big") {
        return <Button onClick={onClick ?? defaultLoginAction ? onLogin : undefined} sx={sx}>Login</Button>
    } else {
        return <MuiButton variant="contained" onClick={onClick ?? defaultLoginAction ? onLogin : undefined}>Login</MuiButton>
    }
}
