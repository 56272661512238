import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ChangelogVersionTag, { ReleaseType } from "../../components/changelog/ChangelogVersionTag";
import ChangelogReleaseDate from "../../components/changelog/ChangelogReleaseDate";
import ChangelogTags from "../../components/changelog/ChangelogTags";
import Link from "next/link";
import { TagType } from "./TagType";
import { Dayjs } from "dayjs";

interface Props {
    releaseType: ReleaseType
    versionCode: string
    title: string
    description: string
    tags: TagType[]
    releaseDate: Dayjs
}

const useStyles = makeStyles(theme => ({
    title: {
        wordBreak: "break-word",
        hyphens: "auto"
    }
}));

export default function ChangelogEntry(props: Props) {
    const { releaseType, versionCode, title, description, tags, releaseDate } = props;

    const classes = useStyles();

    return <>
        <Box display="flex" alignItems="center" flexWrap="wrap" ml={-1.5} overflow="hidden">
            <Box display="flex" alignItems="center" ml={1.5} mb={1}>
                <ChangelogVersionTag releaseType={releaseType} content={`v${versionCode}`}/>
                <Box ml={1.5}>
                    <Link href={`/changelog/v${versionCode}`}>
                        <a><Typography variant="h6" className={classes.title}>{title}</Typography></a>
                    </Link>
                </Box>
            </Box>
            <Box display="flex" flexGrow={1} flexWrap="wrap" overflow="hidden">
                <Box display="flex" alignItems="center" ml={1.5} mb={1} flexGrow={1} overflow="auto">
                    <ChangelogTags tags={tags}/>
                </Box>
                <Box display="flex" alignItems="center" mb={1} ml={1.5}>
                    <ChangelogReleaseDate releaseDate={releaseDate}/>
                </Box>
            </Box>
        </Box>
        <Box mb={1}>
            <Typography color="textSecondary">{description}</Typography>
        </Box>
    </>
}