import { Link, Typography, Box, Grid, Theme } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { SxProps } from "@mui/system";
import Card from "../../card/Card";
import CardTitle from "../../card/CardTitle";

const utm = "?utm_source=wwo&utm_medium=faq"

interface FAQCardProps {
    sx?: SxProps<Theme>
}

export default function FAQCard(props: FAQCardProps) {
    const { sx } = props;

    const { t } = useTranslation();

    return (
        <Card sx={[
            {

            },
            ...(Array.isArray(sx) ? sx : [sx])
        ]}>
            <CardTitle variant="h4" component="h6" align="center" fontWeight="500">{t("faq:title")}</CardTitle>
            <Box>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box px={"2vw"} py={1}>
                            <Typography variant="h6" component="h2">{t("faq:whatiswerewolf.title")}</Typography>
                            <Typography color="textSecondary" sx={{ pb: 0.5 }}>{t("faq:whatiswerewolf.description")}</Typography>
                            <Typography color="textSecondary" sx={{ pb: 0.5 }}>{t("faq:whatiswerewolf.goal")}</Typography>
                            <Typography color="textSecondary">
                                <Trans i18nKey="faq:whatiswerewolf.basedOn" components={{
                                    i: <span style={{ fontStyle: "italic" }} />
                                }} />
                            </Typography>
                        </Box>
                        <Box px={"2vw"} py={1}>
                            <Typography variant="h6" component="h2">{t("faq:roles.title")}</Typography>
                            <Typography color="textSecondary">{t("faq:roles.description")}</Typography>
                            <ul>
                                <li><Typography color="textSecondary">{t("roles:werewolf.name")}</Typography></li>
                                <li><Typography color="textSecondary">{t("roles:witch.name")}</Typography></li>
                                <li><Typography color="textSecondary">{t("roles:amor.name")}</Typography></li>
                                <li><Typography color="textSecondary">{t("roles:seer.name")}</Typography></li>
                                <li><Typography color="textSecondary">{t("roles:hunter.name")}</Typography></li>
                                <li><Typography color="textSecondary">{t("roles:villager.name")}</Typography></li>
                            </ul>
                            <Typography color="textSecondary">
                                <Trans i18nKey="faq:roles.adding" components={{
                                    twitter: <Link href={`https://nextglo.be/twitter${utm}`} target="_blank" rel="noopener" underline="hover" />,
                                    discord: <Link href={`https://nextglo.be/discord${utm}`} target="_blank" rel="noopener" underline="hover" />,
                                    email: <Link href="mailto:contact@nextglobe.net" underline="hover" />
                                }} />
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box px={"2vw"} py={1}>
                            <Typography variant="h6" component="h2">{t("faq:customize.title")}</Typography>
                            <Typography color="textSecondary">{t("faq:customize.description")}</Typography>
                        </Box>
                        <Box px={"2vw"} py={1}>
                            <Typography variant="h6" component="h2">{t("faq:playeramount.title")}</Typography>
                            <Typography color="textSecondary">
                                <Trans i18nKey="faq:playeramount.description" components={{
                                    ngone: <Link href={`https://nextglobe.one/`} target="_blank" rel="noopener" underline="hover" />,
                                    email: <Link href="mailto:contact@nextglobe.net" underline="hover" />
                                }} />
                            </Typography>
                            <Typography color="textSecondary">{t("faq:playeramount.recommendation")}</Typography>
                            <Typography color="textSecondary">
                                <Trans i18nKey="faq:playeramount.updates" components={{
                                    twitter: <Link href={`https://nextglo.be/twitter${utm}`} target="_blank" rel="noopener" underline="hover" />,
                                    discord: <Link href={`https://nextglo.be/discord${utm}`} target="_blank" rel="noopener" underline="hover" />,
                                }} />
                            </Typography>
                        </Box>
                        <Box px={"2vw"} py={1}>
                            <Typography variant="h6" component="h2">{t("faq:ngone.title")}</Typography>
                            <Typography color="textSecondary">{t("faq:ngone.description")}</Typography>
                            <Typography color="textSecondary">{t("faq:ngone.features")}</Typography>
                            <Typography color="textSecondary">
                                <Trans i18nKey="faq:ngone.moreInfo" components={{
                                    ngone: <Link href={`https://nextglobe.one/`} target="_blank" rel="noopener" underline="hover" />
                                }} />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}