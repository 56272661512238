import { useCallback, useEffect, useState } from "react";

interface Props {
    slot: string
}

export default function AdSenseAd(props: Props) {
    // const { slot } = props;

    // const [hydrated, setHydrated] = useState(false);

    // useEffect(() => {
    //     setHydrated(true);
    // }, [setHydrated]);

    // const adTest = (process.env.NODE_ENV || 'development') === "development"

    // const ad = useCallback(node => {
    //     if (node !== null) {
    //         try {
    //             (window.adsbygoogle=window.adsbygoogle||[]).push({})
    //         } catch(e) {
    //             console.error("Ad error", e);
    //         }
    //     }
    // }, []);

    // return <>
    //     {hydrated && <ins ref={ad} className="adsbygoogle"
    //         style={{ display: "flex", justifyContent: "center", alignSelf: "center", flexGrow: 1 }}
    //         data-ad-client="ca-pub-4044158882694212"
    //         data-ad-slot={slot}
    //         data-ad-format="auto"
    //         data-adtest={adTest ? "on" : "off"}
    //         data-full-width-responsive="true"/>}
    // </>
    return null;
}
