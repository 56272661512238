import { FormControlLabel, Switch, Tooltip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import useTranslation from "next-translate/useTranslation";
import NewBadge from "../../../general/NewBadge";

interface DarkModeSettingProps {
    darkMode?: boolean;
    onDarkModeChange?: (dark: boolean) => void;
}

export default function DarkModeSetting(props: DarkModeSettingProps) {
    const { darkMode, onDarkModeChange } = props;

    const { t } = useTranslation();

    const theme = useTheme();

    return <Tooltip title={onDarkModeChange ? "" : "This feature is only available to logged in users"}>
        <FormControlLabel disabled={!onDarkModeChange} control={<Switch color="primary" checked={darkMode ?? false} onChange={(e) => onDarkModeChange(e.target.checked)} />} label={t("landing:darkMode")} />
    </Tooltip>
}