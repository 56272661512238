import { faUser } from "@fortawesome/pro-light-svg-icons";
import { Box, IconButton, Link, Slider, Typography } from "@mui/material";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { useCallback } from "react";
import SquareFontAwesomeIcon from "./util/SquareFontAwesomeIcon";

interface Props {
    playerLimit: number
    setPlayerLimit: (value: number) => void
    playerLimitAllowed: boolean
    max?: number
    maxAllowed?: number
    min?: number
    analyticsCategory: string
}

export default function MaxPlayersSlider(props: Props) {
    const { playerLimit, setPlayerLimit, playerLimitAllowed, max, maxAllowed, min, analyticsCategory } = props;

    const { t } = useTranslation();

    const setPlayerLimitBound = useCallback((newPlayerLimit: number | number[] | ((callback: number) => number)) => {
        const np = typeof (newPlayerLimit) === "number" ? newPlayerLimit : typeof (newPlayerLimit) === "function" ? newPlayerLimit(playerLimit) : 10;
        if (np >= (min || 3) && np <= (max || 16)) {
            setPlayerLimit(np);
            if (np !== playerLimit) {
                const _paq = (window._paq = window._paq || []);
                if (analyticsCategory) {
                    _paq.push(['trackEvent', analyticsCategory, 'Set player limit', np]);
                }
            }
        }
    }, [min, max, setPlayerLimit, playerLimit, analyticsCategory]);

    const decreasePlayerLimit = useCallback(() => {
        setPlayerLimitBound(pl => pl - 1);
    }, [setPlayerLimitBound]);

    const increasePlayerLimit = useCallback(() => {
        setPlayerLimitBound(pl => pl + 1);
    }, [setPlayerLimitBound]);

    return <>
        <Box display="flex" mt={1} alignItems="center">
            <IconButton color="inherit" edge="start" onClick={decreasePlayerLimit} size="large">
                <SquareFontAwesomeIcon icon={faUser} sx={{ width: "0.8em !important", height: "0.8em" }} />
            </IconButton>
            <Box display="flex" mx={1} flexGrow={1} alignItems="center">
                <Slider
                    defaultValue={10}
                    getAriaValueText={(value) => value + " players"}
                    aria-labelledby="max-players-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={min || 3}
                    max={max || 16}
                    value={playerLimit}
                    onChange={(event, value) => setPlayerLimitBound(value)}
                    sx={[
                        !playerLimitAllowed && { color: theme => theme.palette.error.main }
                    ]}
                />
            </Box>
            <IconButton color="inherit" onClick={increasePlayerLimit} size="large">
                <SquareFontAwesomeIcon icon={faUser} sx={{ width: "1.2em !important", height: "1.2em" }} />
            </IconButton>
        </Box>
        <Box mt={0.5}>
            <Typography variant="body2" sx={{ color: theme => playerLimitAllowed ? theme.palette.text.secondary : theme.palette.error.main }}>
                {playerLimitAllowed ? t("settings:playerLimit.slider.helperText.selected", { count: playerLimit }) : <Trans
                    i18nKey="settings:playerLimit.slider.helperText.limitReached"
                    values={{ count: playerLimit, max: maxAllowed, neededSupportLevel: Math.ceil(playerLimit / 2 - 5) }}
                    components={{ ngone: <Link href="https://nextglobe.one/?utm_source=wwo&utm_medium=player-slider-limit-reached" target="_blank" />, sl: <Link /> }}
                />}
            </Typography>
        </Box>
    </>;
}