import { Box, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import i18nConfig from '../i18n';
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import Image from "next/image";

const { locales } = i18nConfig

const useStyles = makeStyles({
    flag: {
        borderRadius: "2px"
    }
});

export default function LanguagePicker(props) {
    const { className } = props;

    const { t, lang } = useTranslation();

    const router = useRouter();

    const classes = useStyles();

    return <Autocomplete
        id="language-picker"
        style={{ width: "100%" }}
        options={locales}
        // classes={{
        //     option: classes.option,
        // }}
        className={className}
        autoHighlight
        getOptionLabel={(option) => t(`languages:${option}.name`)}
        renderOption={(props, option) => {
            const name = t(`languages:${option}.name`);
            const original = t(`languages:${option}.original`);
            return <li {...props}>
                {/* <span>{countryToFlag(option.code)}</span> */}
                <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                    <Image src={"/flags/" + (option === "en" ? "us" : option) + ".svg"} width={24} height={18} alt={t("languages:flag", { flag: t(`languages:${option}.flag`) })} className={classes.flag} />
                </Box>
                {name}{name !== original && ` (${original})`} ({option.toUpperCase()})
            </li>
        }}
        renderInput={(params) => (
            <TextField
                {...params}
                label={t("languages:chooseLanguage")}
                variant="outlined"
                inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                    "data-lpignore": "true"
                }}
                autoComplete="off"
            />
        )}
        value={lang}
        onChange={(_, newValue) => {
            if (newValue != null) {
                router.push(router.route, router.asPath, { locale: newValue });
                if (document.activeElement) document.activeElement.blur();
            }
        }}
    />
}