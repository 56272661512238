import React from "react";
import { Box, FormControlLabel, Link, Switch, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from "next-translate/useTranslation";
import { useMemo } from "react";
import LanguagePicker from "../../LanguagePicker";
import { BasePlayer } from "../../../types/BasePlayer";
import DarkModeSetting from "./settings/DarkModeSetting";
import useWerewolfTheme from "../../../theme/useWerewolfTheme";
import { themes } from "../../../theme/Themes";
import Image from "next/image";

const useStyles = makeStyles({
    languagePickerWrapper: {
        display: "flex",
        alignItems: "center"
    },
    languagePickerFlag: {
        borderRadius: "4px"
    },
    languagePicker: {
        flexGrow: 1,
        marginRight: "16px"
    }
});

const translators: { [lang: string]: { name: string, link?: string }[] } = {
    "de": [
        { "name": "NextGlobe" },
        { "name": "Fabb111", "link": "https://twitter.com/Fabb111Official" }
    ],
    "en": [
        { "name": "NextGlobe" },
        { "name": "Fabb111", "link": "https://twitter.com/Fabb111Official" }
    ],
    "tr": [
        { "name": "Sozixle", "link": "https://twitter.com/Sozixle" }
    ]
}

interface CardSettingsContentProps {
    player?: BasePlayer
}

export default function CardSettingsContent(props: CardSettingsContentProps) {
    const { player } = props;

    const classes = useStyles();

    const { theme, setTheme } = useWerewolfTheme();

    const { t, lang } = useTranslation();

    const translatedBy = useMemo(() => {
        return translators[lang].map((translator, idx) => {
            return <React.Fragment key={translator.name}>
                {translator.link ? <Link href={translator.link} target="_blank" underline="hover">{translator.name}</Link> : <span>{translator.name}</span>}
                {idx + 1 < translators[lang].length && (idx + 2 === translators[lang].length ? ` ${t("common:and")} ` : ", ")}
            </React.Fragment>
        })
    }, [t, lang]);

    return <>
        <div className={classes.languagePickerWrapper}>
            <LanguagePicker className={classes.languagePicker} />
            <Image src={"/flags/" + (lang === "en" ? "us" : lang) + ".svg"} width={88} height={66} alt={t("languages:flag", { flag: t(`languages:${lang}.flag`) })} draggable={false} className={classes.languagePickerFlag} />
        </div>
        <Box mb={1}>
            <Typography variant="caption" color="textSecondary">{t("languages:translatedBy")} {translatedBy}</Typography>
        </Box>
        <DarkModeSetting darkMode={theme.palette.mode === "dark"} onDarkModeChange={player?.userId !== undefined ? (dark) => {
            setTheme(dark ? themes.dark : themes.light);
            window.localStorage.setItem("theme", dark ? "dark" : "light");
        } : undefined} />
    </>
}