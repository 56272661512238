import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useTranslation from "next-translate/useTranslation";

export enum ReleaseType {
    CURRENT = "current",
    MAJOR = "major",
    MINOR = "minor"
}

interface Props {
    releaseType: ReleaseType
    content: string
}

const useStyles = makeStyles({
    tag: {
        cursor: "pointer",
        userSelect: "none"
    },
    text: {
        color: "white",
        fontWeight: 600
    }
});

export default function ChangelogVersionTag(props: Props) {
    const { releaseType, content } = props;

    const { t } = useTranslation();

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Tooltip title={t(`changelog:releaseType.${releaseType.toString()}`)} placement="top" arrow>
            <Box px="5px" py="2px" bgcolor={releaseType === ReleaseType.CURRENT ? theme.palette.secondary.main : releaseType === ReleaseType.MAJOR ? theme.palette.primary.main : theme.palette.grey[500]} borderRadius="3px" className={classes.tag}>
                <Typography className={classes.text} variant="body2">{content}</Typography>
            </Box>
        </Tooltip>
    );
}