import axios, { CancelTokenSource } from "axios";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { BasePlayer } from "../../types/BasePlayer";
import api from "../../utils/api";
import EditUsername from "./EditUsername";
import EditUsernameButton from "./EditUsernameButton";
import PlayerAvatar from "./PlayerAvatar";
import PlayerStatus from "./PlayerStatus";
import PlayerName from "./PlayerName";
import { SxProps } from '@mui/system';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from "@mui/material";
import { motion } from "framer-motion";
import SquareFontAwesomeIcon from "../util/SquareFontAwesomeIcon";
import { faPen } from "@fortawesome/pro-light-svg-icons";
import useMutateSessionToken from "../../hooks/sessiontoken/useMutateSessionToken";

interface Props {
    player?: BasePlayer
    viewer?: BasePlayer
    error?: boolean
    mutate?: (data?: any, shouldRevalidate?: boolean) => Promise<any>
    wrap?: boolean
    truncate?: boolean
    sub?: ReactNode
    onLogin?: () => void
    sx?: SxProps<Theme>
}

const avatarContainerVariants = {
    noedit: {},
    edit: {}
}

const avatarOverlayVariants = {
    noedit: {
        opacity: 0
    },
    edit: {
        opacity: 1
    }
}

export default function PlayerProfile(props: Props) {
    const { player, viewer, error, mutate, wrap, truncate, sub, sx = [] } = props;

    const cancelToken = useRef<CancelTokenSource>(null);
    const [editing, setEditing] = useState(false);
    const [usernameUpdating, setUsernameUpdating] = useState(false);

    const [needsAccount, setNeedsAccount] = useState(false);

    const onEditUsernameButtonClick = useCallback(() => {
        setEditing(true);
    }, [setEditing]);

    const onClickUsername = useCallback(() => {
        if (mutate) {
            setEditing(true);
        }
    }, [setEditing, mutate]);

    useEffect(() => {
        return () => {
            cancelToken.current?.cancel();
        }
    }, [cancelToken]);

    const updateUsername = useCallback(async (username) => {
        if (username !== player?.username) {
            setUsernameUpdating(true);
            if (player !== undefined) mutate({ ...player, username }, false);
            try {
                cancelToken.current = axios.CancelToken.source();
                await api.post("/player", { username }, { cancelToken: cancelToken.current.token });
                mutate();
                setEditing(false);
            } catch (err) {
                if (axios.isCancel(err)) {
                    return;
                }
                console.error("An error occured during updating the username", err);
            }
            setUsernameUpdating(false);
        } else {
            setEditing(false);
        }
    }, [player, cancelToken, setUsernameUpdating, mutate, setEditing])

    const onFinishEditing = useCallback(async (username) => {
        await updateUsername(username);
    }, [updateUsername]);

    const onCancelEditing = useCallback(() => {
        setEditing(false);
    }, [setEditing]);

    const onEditAvatarClick = useCallback(() => {
        if (player.userId) {
            window.open("https://account.nextglobe.net", '_blank').focus();
        } else {
            setNeedsAccount(true);
        }
    }, [player, setNeedsAccount]);

    const mutateSessionToken = useMutateSessionToken();

    const onLogin = useCallback(() => {
        mutateSessionToken(null);
        window.location.href = process.env.apiUrl + "/login";
    }, [mutateSessionToken]);

    return <Box sx={[
        { overflow: truncate ? "hidden" : "auto" },
        {
            display: "flex",
            alignItems: "center",
            flexGrow: 1
        },
        wrap && { flexWrap: "wrap" },
        ...(Array.isArray(sx) ? sx : [sx])
    ]}>
        {mutate ? <Box component={motion.div} sx={{ position: "relative" }} initial="noedit" whileHover="edit" variants={avatarContainerVariants}>
            <Box
                component={motion.div}
                variants={avatarOverlayVariants}
                onClick={onEditAvatarClick}
                sx={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    background: "rgba(0, 0, 0, 0.3)",
                    borderRadius: "50%",
                    zIndex: 1,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <SquareFontAwesomeIcon icon={faPen} sx={{ height: "1.5rem !important", width: "1.5rem !important", color: "#fff" }} />
            </Box>
            <PlayerAvatar player={player} />
        </Box> : <PlayerAvatar player={player} />}
        <Box sx={[
            {
                margin: "-2px -8px 0",
                marginLeft: "8px",
                // marginLeft: 16,
                flexGrow: {
                    xs: 1,
                    sm: 0
                },
                flexBasis: {
                    xs: "60%",
                    sm: "auto"
                },
                height: "102px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "auto"
            },
            editing && {
                flexGrow: {
                    sm: 1
                },
                marginLeft: "16px",
                flexBasis: {
                    sm: "30%"
                },
                marginRight: "1px",
                overflow: "hidden !important"
            }
        ]}>
            {editing ? <EditUsername
                initial={player?.username}
                disabled={usernameUpdating}
                onFinishEditing={onFinishEditing}
                onCancelEditing={onCancelEditing}
            /> : <PlayerName
                loading={!player}
                username={!error && player ? player.username : "Unknown"}
                sub={sub ?? <PlayerStatus player={player} />}
                onClick={onClickUsername}
                editable={!!mutate}
            />}
        </Box>
        {(player && !editing && mutate) && <EditUsernameButton onClick={onEditUsernameButtonClick} />}
        {mutate && <Dialog open={needsAccount} onBackdropClick={() => setNeedsAccount(false)}>
            <DialogTitle>You need a NextGlobe account to be able change your avatar</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Just click on login and create a new account or log in to your existing account - it only takes a few seconds
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={() => setNeedsAccount(false)}>Cancel</Button>
                <Button onClick={onLogin}>Login</Button>
            </DialogActions>
        </Dialog>}
    </Box>
}