import React, { ReactNode, ReactNodeArray } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';

interface NextComposedProps {
  as?: string | any
  href: string | any
  prefetch?: boolean
  className?: string
}

const NextComposed = React.forwardRef<any, NextComposedProps>(function NextComposed(props: NextComposedProps, ref: any) {
  const { as, href, ...other } = props;

  return (
    <NextLink href={href} as={as}>
      <a ref={ref} {...other} />
    </NextLink>
  );
});

interface LinkProps extends MuiLinkProps {
  activeClassName?: string
  as?: string | any
  className?: string
  children: ReactNode | ReactNodeArray
  href: string | any
  innerRef?: () => void | any
  naked?: boolean
  onClick?: () => void
  prefetch?: boolean
}

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
function Link(props: LinkProps) {
  const {
    href,
    activeClassName = 'active',
    className: classNameProps,
    innerRef,
    naked,
    ...other
  } = props;

  const router = useRouter();
  const pathname = typeof href === 'string' ? href : href.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName,
  });

  if (naked) {
    return <NextComposed className={className} ref={innerRef} href={href} {...other} />;
  }

  return (
    <MuiLink component={NextComposed} className={className} ref={innerRef} href={href} {...other} />
  );
}

// eslint-disable-next-line react/display-name
export default React.forwardRef<any, LinkProps>((props: LinkProps, ref: any) => <Link {...props} innerRef={ref} />);