import {
    Typography,
    TextField,
    FormControlLabel,
    Checkbox,
    Box,
    IconButton,
    Tooltip,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import Button from "../../Button";
import useTranslation from "next-translate/useTranslation";
import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import api from "../../../utils/api";
import { faGiftCard, faInfoCircle, faTimes } from "@fortawesome/pro-light-svg-icons";
import SegmentedControl from "../../SegmentedControl";
import MaxPlayersSlider from "../../MaxPlayersSlider";
import PromoCodeDialog from "../../dialog/PromoCodeDialog";
import Card from "../../card/Card";
import CardTitle from "../../card/CardTitle";
import SquareFontAwesomeIcon from "../../util/SquareFontAwesomeIcon";
import useMutateRoomConnection from "../../../hooks/roomconnection/useMutateRoomConnection";
import { RoomData } from "../../../types/room/RoomData";
import usePlayer from "../../../swr/usePlayer";
import useSessionToken from "../../../hooks/sessiontoken/useSessionToken";
import useHighestRole, { Role } from "../../../hooks/use-highest-role";

const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜabcdefghijklmnopqrstuvwxyzäöü0123456789_ ";
const maxLength = 30;

const defaultMaxPlayers = 10;

function validateRoomName(roomName: string) {
    return roomName.length > 0 && roomName.length <= maxLength && roomName.split("").every((char, idx) => (allowedCharacters.indexOf(char) !== -1 || (((idx === 0 || roomName[idx - 1] === " ") && roomName.length > idx + 1 && roomName[idx + 1] !== " ") && char === "#")));
}

function getPlayerLimit(highestRole: Role) {
    switch (highestRole) {
        case Role.Team:
            return 100;
        case Role.NextGlobeOneUltra:
            return defaultMaxPlayers + 40;
        case Role.NextGlobeOneStandard:
            return defaultMaxPlayers + 20;
        case Role.NextGlobeOneBasic:
            return defaultMaxPlayers + 5;
        default:
            return defaultMaxPlayers;
    }
}

export default function CreateRoomCard() {
    const router = useRouter();

    const sessionToken = useSessionToken();

    const { connect } = useMutateRoomConnection();

    const { t, lang } = useTranslation();

    const theme = useTheme();

    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    const big = useMediaQuery(theme.breakpoints.up("lg"));

    const roomNameRef = useRef(null);

    const { player } = usePlayer(sessionToken);

    const [roomName, setRoomName] = useState("");
    const [autoName, setAutoName] = useState(true);
    const [playerLimit, setPlayerLimit] = useState(defaultMaxPlayers);
    const [promoCode, setPromoCode] = useState(null);
    const [promoCodeDialogOpen, setPromoCodeDialogOpen] = useState(false);
    const [autoCopy, setAutoCopy] = useState(true);
    const [creatingRoom, setCreatingRoom] = useState(false);
    const [joiningRoom, setJoiningRoom] = useState(false);

    const roomNameValid = useMemo(() => {
        return validateRoomName(roomName);
    }, [roomName]);

    useEffect(() => {
        // Prefetch the room page
        router.prefetch("/room/[id]")
    }, [router]);

    useEffect(() => {
        if (!autoName) {
            if (roomNameRef.current) {
                roomNameRef.current.focus();
            }
        }
    }, [autoName, roomNameRef]);

    const highestRole = useHighestRole(player?.roles);

    const maxPlayers = useMemo(() => {
        const max = getPlayerLimit(highestRole);
        if (promoCode?.promo?.reward === "INCREASE_MAX_PLAYER_LIMIT" && promoCode.promo.value > max) {
            return promoCode.promo.value;
        }
        return max;
    }, [promoCode, highestRole]);

    useEffect(() => {
        if (defaultMaxPlayers !== maxPlayers) {
            setPlayerLimit(maxPlayers);
        }
    }, [maxPlayers, setPlayerLimit]);

    const playerLimitAllowed = useMemo(() => {
        return playerLimit <= maxPlayers;
    }, [playerLimit, maxPlayers]);

    const createRoom = useCallback(async () => {
        setCreatingRoom(true);
        try {
            const result = await api.post("/room", { roomName: autoName || roomName.trim().length === 0 ? null : roomName.trim(), playerLimit: playerLimit, promoCode: promoCode?.code });
            setJoiningRoom(true);
            const roomData = result.data as RoomData;
            connect(roomData.id);
            const _paq = (window._paq = window._paq || []);
            _paq.push(['trackEvent', 'Room', 'Room created']);
            if (autoCopy) {
                if (navigator.clipboard) {
                    try {
                        await navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}${lang !== "en" ? `/${lang}` : ""}/room/${roomData.id}`);
                        _paq.push(['trackEvent', 'Room', 'Auto copy room url']);
                    } catch (e) { }
                }
            }
            router.push("/room/[id]", "/room/" + roomData.id);
        } catch (err) {
            console.error("An error occured during creating the room", err);
        }
        setCreatingRoom(false);
    }, [setCreatingRoom, autoName, roomName, playerLimit, promoCode, lang, autoCopy, setJoiningRoom, connect, router]);

    return (
        <Card>
            <Box display="flex" flexDirection="column" pb={1.5}>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                    <Box display="flex" flexGrow={1} alignItems="center">
                        <CardTitle component="h2" variant="h4" sx={{ pb: 0 }}>{t("landing:createRoom.title")}</CardTitle>
                    </Box>
                    <Box display="flex" alignItems="center">
                        {(big || sm) && promoCode && <Box display="flex" alignItems="center" mr={1.5}>
                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                mr: 0.5,
                                cursor: "pointer"
                            }} onClick={() => setPromoCode(null)}>
                                <SquareFontAwesomeIcon icon={faTimes} />
                            </Box>
                            <Typography variant="caption">Applied promo code: <Tooltip title={promoCode.type}><Typography component="span" sx={{ cursor: "pointer" }}>{promoCode.code}</Typography></Tooltip></Typography>
                        </Box>}
                        <Box display="flex" alignItems="center">
                            <Tooltip title="Promotional code">
                                <IconButton onClick={() => setPromoCodeDialogOpen(true)} size="large">
                                    <SquareFontAwesomeIcon icon={faGiftCard} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                {!big && !sm && promoCode && <Box display="flex" alignItems="center">
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 0.5,
                        cursor: "pointer"
                    }} onClick={() => setPromoCode(null)}>
                        <SquareFontAwesomeIcon icon={faTimes} />
                    </Box>
                    <Typography variant="caption">Applied promo code: <Tooltip title={promoCode.type}><Typography component="span" sx={{ cursor: "pointer" }}>{promoCode.code}</Typography></Tooltip></Typography>
                </Box>}
            </Box>
            <Box display="flex" alignItems="center" ml={-2} flexWrap="wrap" mt={1} mb={0.5}>
                <Box ml={2} mb={1}>
                    <Typography variant="h5" component="p" id="create-room-name-label">{t("landing:createRoom.name.title")}</Typography>
                </Box>
                <Box display="flex" ml={2} mb={1}>
                    <SegmentedControl id="autoName" value={autoName ? "auto" : "manual"} onChange={(key) => setAutoName(key === "auto")} values={[{ key: "auto", label: t("landing:createRoom.name.variants.auto.name") }, { key: "manual", label: t("landing:createRoom.name.variants.custom.name") }]} />
                </Box>
            </Box>
            <TextField
                inputRef={roomNameRef}
                variant="outlined"
                // label={t("landing:roomName")}
                aria-labelledby="create-room-name-label"
                value={autoName ? "" : roomName}
                placeholder={autoName ? t("landing:createRoom.name.variants.auto.placeholder") : null}
                onChange={(event) => setRoomName(event.currentTarget.value)}
                error={roomName.length > 0 && !roomNameValid && !autoName}
                helperText={autoName ? t("landing:createRoom.name.variants.auto.length") : (roomName.length === 0 || roomNameValid) ? t("landing:createRoom.name.variants.custom.length", { count: roomName.length, max: maxLength }) : t("landing:createRoom.name.variants.custom.invalid")}
                FormHelperTextProps={{ sx: { textAlign: roomName.length === 0 || roomNameValid || autoName ? "right" : "left" } }}
                disabled={/*autoName || */creatingRoom || joiningRoom}
                inputProps={{
                    "data-lpignore": "true"
                }}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && roomNameValid && playerLimitAllowed) {
                        createRoom();
                    }
                }}
                onMouseDown={() => {
                    if (autoName) {
                        setAutoName(false);
                    }
                }}
                onBlur={() => {
                    if (roomName.length === 0 || !roomNameValid) {
                        setAutoName(true);
                    }
                }}
                fullWidth
            />
            <Box mt={1}>
                <Typography id="max-players-slider" variant="h5" component="p">{t("settings:playerLimit.title")} – {playerLimit}</Typography>
                <Box display="flex" flexWrap="wrap" mr={-4} mb={-2}>
                    <Box flexBasis={"70%"} flexGrow={1} mr={4} mb={2}>
                        <MaxPlayersSlider playerLimit={playerLimit} setPlayerLimit={setPlayerLimit} playerLimitAllowed={playerLimitAllowed} max={maxPlayers + 6} maxAllowed={maxPlayers} analyticsCategory="Create room" />
                    </Box>
                    <Box display="flex" alignItems="center" mr={4} mb={2} flexBasis={"24%"} flexGrow={1}>
                        <Box display="flex" flexShrink={0}>
                            <SquareFontAwesomeIcon icon={faInfoCircle} sx={{ color: "text.secondary" }} />
                        </Box>
                        <Box display="flex" ml={1.5}>
                            <Typography variant="caption" color="textSecondary">{t("settings:playerLimit.slider.helperText.change")}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" flexWrap="wrap" mt={2}>
                <Box sx={{ flexGrow: 1, mt: 1, py: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={autoCopy}
                                onChange={() => setAutoCopy(ac => !ac)}
                                name="autoCopy"
                                color="primary"
                            />
                        }
                        label={t("landing:createRoom.autoCopy")}
                    />
                </Box>
                <Box ml="auto" mt={1} sx={{ flexGrow: { xs: 1, sm: 0 } }}>
                    <Button
                        disabled={creatingRoom || joiningRoom || (roomName.trim().length > 0 && !roomNameValid && !autoName) || !playerLimitAllowed}
                        onClick={createRoom}
                        sx={{ width: { xs: "100%", sm: "auto" } }}
                    >
                        {joiningRoom ? t("landing:createRoom.states.joining") : creatingRoom ? t("landing:createRoom.states.creating") : ((roomNameValid || autoName) && playerLimitAllowed) ? t("landing:createRoom.states.valid") : t("landing:createRoom.states.invalid")}
                    </Button>
                </Box>
            </Box>
            <PromoCodeDialog open={promoCodeDialogOpen} onClose={() => setPromoCodeDialogOpen(false)} apply={async (code) => {
                try {
                    const result = await api.post("/promocode/check", { code });
                    setPromoCode({ code, ...result.data });
                    setPromoCodeDialogOpen(false);
                    return { success: true };
                } catch (e) {
                    return { success: false, error: e };
                }
            }} />
        </Card>
    );
}