import { Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';
import { domAnimation, LazyMotion, m } from "framer-motion";
import { ReactNode } from "react";
import { Box } from "@mui/system";

const useStyles = makeStyles(theme => ({
    profileDescriptionName: {
        padding: "2px 8px"
        // lineHeight: 1.1,
        // fontSize: 28
    },
    profileDescriptionLevel: {
        // lineHeight: 1.1,
        marginTop: -4,
        padding: "0 8px"
    },
    profileDescriptionLevelBig: {
        fontSize: 16
    }
}));

interface Props {
    loading: boolean
    username: string
    editable?: boolean
    sub: ReactNode
    onClick?: () => void
}

export default function PlayerName(props: Props) {
    const { loading, username, editable, sub, onClick } = props;

    const classes = useStyles();
    const theme = useTheme();

    const small = useMediaQuery(theme.breakpoints.down('md'));

    return <>
        <LazyMotion features={domAnimation}>
            <Box
                component={m.div}
                sx={{ borderRadius: "8px" }}
                initial={{ backgroundColor: theme.palette.background.paper }}
                animate={{ backgroundColor: theme.palette.background.paper }}
                transition={{ type: "tween", ease: "easeInOut", duration: 0.2 }}
                whileHover={editable && !loading ? { backgroundColor: theme.palette.mode === "dark" ? "#2D3239" : "#DFDFDF", transition: { type: "tween", ease: "easeInOut", duration: 0.2 } } : undefined}
            >
                <Typography variant="h5" component="p" className={classes.profileDescriptionName} onClick={editable && !loading ? onClick : undefined} noWrap={!small}>{loading ? <Skeleton animation="wave" width={180} /> : username}</Typography>
            </Box>
        </LazyMotion>
        <Box className={classes.profileDescriptionLevel}>
            {loading ? <Typography variant="body2" color="textSecondary" noWrap={!small}>
                <Skeleton animation="wave" width={90} />
            </Typography> : sub}
        </Box>
    </>
}
