import { faPen } from "@fortawesome/pro-light-svg-icons";
import { IconButton, Tooltip } from "@mui/material";
import SquareFontAwesomeIcon from "../util/SquareFontAwesomeIcon";

interface Props {
    onClick: () => void
}

export default function EditUsernameButton(props: Props) {
    const { onClick } = props;

    return (
        <Tooltip title="Edit username" placement="top">
            <IconButton onClick={onClick} size="large" sx={{ ml: { sm: "4px" } }}>
                <SquareFontAwesomeIcon icon={faPen} />
            </IconButton>
        </Tooltip>
    );
}