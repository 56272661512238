import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import {
    ClickAwayListener,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import SquareFontAwesomeIcon from "../util/SquareFontAwesomeIcon";

interface Props {
    initial: string
    disabled: boolean
    onFinishEditing: (username: string) => Promise<void>
    onCancelEditing: () => void
}

const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜabcdefghijklmnopqrstuvwxyzäöü0123456789_";
const maxLength = 20;

function validateUsername(username) {
    return username.length <= maxLength && username.split("").every(char => allowedCharacters.indexOf(char) !== -1);
}

export default function EditUsername(props: Props) {
    const { initial, disabled, onFinishEditing, onCancelEditing } = props;

    const { t } = useTranslation();

    const [username, setUsername] = useState(() => initial || "");

    const usernameValid = useMemo(() => {
        return username && validateUsername(username);
    }, [username]);

    const finish = useCallback(() => {
        if(usernameValid) {
            onFinishEditing(username);
        } else {
            onCancelEditing();
        }
    }, [usernameValid, username, onFinishEditing, onCancelEditing]);

    return (
        <ClickAwayListener onClickAway={finish}>
            <FormControl variant="outlined" fullWidth error={username.length > 0 && !usernameValid} disabled={disabled} sx={{ flexShrink: 0, mt: "24px" }}>
                <InputLabel htmlFor="username-edit-textfield">{t("common:newUsername")}</InputLabel>
                <OutlinedInput
                    id="username-edit-textfield"
                    autoComplete="off"
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoFocus={true}
                    value={username}
                    onChange={(event) => setUsername(event.currentTarget.value)}
                    onKeyDown={(event) => {
                        if(event.key === "Enter") {
                            finish();
                        }
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label={usernameValid ? "Update username" : "Cancel"}
                                onClick={finish}
                                disabled={disabled}
                                edge="end"
                                size="large"
                            >
                                <SquareFontAwesomeIcon icon={(usernameValid && username !== initial) ? faCheck : faTimes}/>
                            </IconButton>
                        </InputAdornment>
                    }
                    label={t("common:newUsername")}
                />
                {(username.length > 0 && !usernameValid) ? <FormHelperText id="username-edit-textfield-helper-text">Invalid username</FormHelperText> : <FormHelperText id="username-edit-textfield-helper-text" style={{textAlign: "right"}}>{username.length}/{maxLength}</FormHelperText>}
            </FormControl>
        </ClickAwayListener>
    );
}