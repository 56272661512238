import { Typography, TypographyProps } from "@mui/material";
import { ReactNode } from "react";

interface CardTitleProps {
    children?: ReactNode;
}

export default function CardTitle(props: TypographyProps<any> & CardTitleProps) {
    const { children, sx, ...other } = props;

    return <Typography fontWeight="500" sx={{ pb: "12px", ...(sx ?? {}) }} {...other}>{children}</Typography>
}