import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo, useState } from "react";
import useIconStyles from "../../utils/useIconStyles";

interface Props {
    open: boolean
    onClose: () => void
    apply: (code: string) => Promise<{ success: boolean, error?: any }>
}

const maxLength = 30;
const allowedCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜabcdefghijklmnopqrstuvwxyzäöü0123456789_-";

function validateCode(code: string) {
    return code.length > 0 && code.length <= maxLength && code.split("").every((char, idx) => (allowedCharacters.indexOf(char) !== -1 || (((idx === 0 || code[idx - 1] === " ") && code.length > idx + 1 && code[idx + 1] !== " ") && char === "#")));
}

const useStyles = makeStyles(theme => ({
    inputHelperText: {
        textAlign: "right"
    }
}));

export default function PromoCodeDialog(props: Props) {
    const { open, onClose, apply } = props;

    const theme = useTheme();
    const classes = useStyles();
    const iconClasses = useIconStyles();

    const [code, setCode] = useState("");
    const [invalid, setInvalid] = useState(null);
    const [invalidReason, setInvalidReason] = useState(null);
    const [applying, setApplying] = useState(false);

    const codeValid = useMemo(() => {
        return validateCode(code);
    }, [code]);

    const tryToApply = useCallback(async (code: string) => {
        setApplying(true);
        const result = await apply(code);
        if(result.success) {
            setInvalid(null);
            setInvalidReason(null);
        } else {
            setInvalid(code);
            setInvalidReason(result.error?.response?.status === 429 ? "You are applying promo codes too fast" : result.error?.response?.status === 404 ? "Invalid promo code" : "Unknown error");
        }
        setApplying(false);
    }, [apply, setApplying, setInvalid, setInvalidReason]);

    return <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle>Promotional code</DialogTitle>
        <DialogContent>
            <TextField
                label="Code"
                variant="filled"
                value={code}
                onChange={e => setCode(e.currentTarget.value)}
                autoFocus
                fullWidth
                onKeyDown={(event) => {
                    if(event.key === "Enter") {
                        if(code === "") {
                            event.preventDefault();
                            onClose();
                        } else if(invalid === code) {
                            event.preventDefault();
                        } else if(codeValid) {
                            event.preventDefault();
                            tryToApply(code);
                        }
                    }
                }}
                helperText={invalid === code ? invalidReason : code.length === 0 || codeValid ? `${code.length}/${maxLength}` : "Invalid promo code format"}
                error={invalid === code || (code.length > 0 && !codeValid)}
                FormHelperTextProps={((code.length === 0 || codeValid) && invalid !== code) ? {
                    className: classes.inputHelperText
                } : null}
            />
            <Box display="flex" alignItems="center" mt={1}>
                <Box display="flex" flexShrink={0}>
                    <FontAwesomeIcon icon={faInfoCircle} className={iconClasses.squareIcon} color={theme.palette.text.secondary}/>
                </Box>
                <Box display="flex" ml={1.5}>
                    <Typography variant="caption" color="textSecondary">Promotional codes may be found on our social media accounts or be given away during special events</Typography>
                </Box>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancel
            </Button>
            <Button onClick={() => {
                if(code === "") {
                    onClose();
                } else if(codeValid && invalid !== code) {
                    tryToApply(code);
                }
            }} color="primary" disabled={applying}>
                Apply
            </Button>
        </DialogActions>
    </Dialog>
}