import useTranslation from "next-translate/useTranslation";
import CardSettingsContent from "./CardSettingsContent";
import Card from "../../card/Card";
import CardTitle from "../../card/CardTitle";
import useSessionToken from "../../../hooks/sessiontoken/useSessionToken";
import usePlayer from "../../../swr/usePlayer";

export default function SettingsCard() {
    const sessionToken = useSessionToken();

    const player = usePlayer(sessionToken);

    const { t } = useTranslation();

    return <Card>
        <CardTitle variant="h4" component="h6">{t("landing:settings")}</CardTitle>
        <CardSettingsContent player={player.player} />
    </Card>
}