import { Button, Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";

interface UpgradeButtonProps {
    sx?: SxProps
}

export default function UpgradeButton(props: UpgradeButtonProps) {
    const { sx } = props;

    return <a href="https://nextglobe.one/?utm_source=wwo&utm_medium=upgrade-button&utm_content=account-card" target="_blank" rel="noreferrer">
        <Button variant="text" size="small" sx={{
            background: "linear-gradient(90deg, #F15A26, #FFA600)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "#F15A26"
        }}>
            Upgrade
        </Button>
    </a>
}