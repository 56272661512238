import Button from "../../Button";
import useTranslation from "next-translate/useTranslation";
import Link from "../../Link";
import { useEffect, useState } from "react";
import PlayerProfile from "../../player/PlayerProfile";
import ProfileLoginButton from "../../player/LoginButton";
import { Box } from "@mui/system";
import Card from "../../card/Card";
import CardTitle from "../../card/CardTitle";
import usePlayer from "../../../swr/usePlayer";
import useSessionToken from "../../../hooks/sessiontoken/useSessionToken";
import { RoomData } from "../../../types/room/RoomData";
import useMutateRoomConnection from "../../../hooks/roomconnection/useMutateRoomConnection";
import ManageAccountButton from "../../player/ManageAccountButton";

interface JoinRoomCardProps {
    roomData: RoomData
}

export default function JoinRoomCard(props: JoinRoomCardProps) {
    const { roomData } = props;

    const { t } = useTranslation();

    const sessionToken = useSessionToken();
    const { connecting, connectionError, clearError, connect, disconnect } = useMutateRoomConnection();

    const { player, error, mutate } = usePlayer(sessionToken);

    const [joiningRoom, setJoiningRoom] = useState(false);

    useEffect(() => {
        if (connectionError) {
            disconnect();
            setJoiningRoom(false);

            const id = setTimeout(() => {
                clearError();
            }, 5000);

            return () => {
                clearTimeout(id);
            }
        }
    }, [connectionError, disconnect, setJoiningRoom, clearError]);

    return <Card>
        <CardTitle variant="h4" component="h6" sx={{ pb: 0 }}>{t("landing:joinRoom")}{roomData ? (": " + roomData.name) : ""}</CardTitle>
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <PlayerProfile player={player} viewer={player} error={error} mutate={mutate} />
            <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                {player && (player.userId ? <ManageAccountButton /> : <ProfileLoginButton size="small" defaultLoginAction />)}
            </Box>
        </Box>
        <Box sx={{ display: "flex", mt: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <Link href="/" underline="none" onClick={() => {
                disconnect();
            }}>{t("landing:cancel")}</Link>
            <Button disabled={joiningRoom || connecting || !roomData} onClick={() => {
                setJoiningRoom(true);
                connect(roomData.id);
            }} sx={[
                { ml: 2 },
                connectionError && { backgroundColor: theme => theme.palette.error.main }
            ]}>{connecting ? t("landing:connectingToRoom") : joiningRoom ? t("landing:joiningRoom") : connectionError ? connectionError === "ALREADY_CONNECTED" ? "Already connected" : connectionError : t("landing:joinRoom")}</Button>
        </Box>
    </Card>
}