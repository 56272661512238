import { Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { RoomData } from '../../types/room/RoomData';
import Footer from '../footer/Footer';
import Header from '../Header';
import LandingContent from './LandingContent';

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
    },
    contentContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    footerDivider: {
        marginTop: "auto"
    }
});

type LandingPageProps = {
    landingPageType: "create",
    roomData?: RoomData
} | {
    landingPageType: "join",
    roomData: RoomData
}

export default function LandingPage(props: LandingPageProps) {
    const { landingPageType, roomData } = props;

    const classes = useStyles();

    return <div>
        <div className={classes.container}>
            {/* Ezoic - top_of_page - top_of_page */}
            <div id="ezoic-pub-ad-placeholder-101" />
            {/* End Ezoic - top_of_page - top_of_page */}
            <div className={classes.contentContainer}>
                <Header />
                {/* Ezoic - under_page_title - under_page_title */}
                <div id="ezoic-pub-ad-placeholder-102" />
                {/* End Ezoic - under_page_title - under_page_title */}
                <LandingContent roomCardType={landingPageType} roomData={roomData} />
            </div>
            <Divider className={classes.footerDivider} />
            <Footer />
        </div>
    </div>
}