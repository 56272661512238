import useHighestRole, { Role } from "../../hooks/use-highest-role";
import { BasePlayer } from "../../types/BasePlayer";
import NextGlobeOneBasicStatus from "./status/NextGlobeOneBasicStatus";
import NextGlobeOneStandardStatus from "./status/NextGlobeOneStandardStatus";
import NextGlobeOneStatus from "./status/NextGlobeOneStatus";
import NextGlobeOneUltraStatus from "./status/NextGlobeOneUltraStatus";
import TeamStatus from "./status/TeamStatus";
import UnregisteredStatus from "./status/UnregisteredStatus";
import UserStatus from "./status/UserStatus";

interface Props {
    player: BasePlayer
}

export default function PlayerStatus(props: Props) {
    const { player } = props;

    const highestRole = useHighestRole(player.roles);

    if (player.roles.includes(Role.Team)) {
        return <TeamStatus />
    } else if (highestRole === Role.NextGlobeOneUltra) {
        return <NextGlobeOneUltraStatus />
    } else if (highestRole === Role.NextGlobeOneStandard) {
        return <NextGlobeOneStandardStatus />
    } else if (highestRole === Role.NextGlobeOneBasic) {
        return <NextGlobeOneBasicStatus />
    } else if (highestRole === Role.NextGlobeOne) {
        return <NextGlobeOneStatus />
    } else if (player.userId) {
        return <UserStatus />
    } else {
        return <UnregisteredStatus />
    }
}
