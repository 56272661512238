import { Box, Button, Divider, Typography, Theme } from "@mui/material";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import ChangelogTagsTag from "../../changelog/ChangelogTagsTag";
import ChangelogEntry from "../../changelog/ChangelogEntry";
import versions from "../../../versions";
import { ReleaseType } from "../../changelog/ChangelogVersionTag";
import { TagType } from "../../changelog/TagType";
import Card from "../../card/Card";
import CardTitle from "../../card/CardTitle";
import { SxProps } from "@mui/system";

export default function ChangelogCard(props: { sx?: SxProps<Theme> }) {
    const { sx } = props;

    const { t } = useTranslation();

    return <Card sx={[
        {
            display: "flex",
            flexWrap: "wrap"
        },
        ...(Array.isArray(sx) ? sx : [sx])
    ]}>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
            <Link href="/changelogs">
                <a><CardTitle variant="h4" component="h5">{t("changelog:changelogs.title")}</CardTitle></a>
            </Link>
        </Box>
        <Box component="ul" sx={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            mb: 2,
            mt: 1,
            flexGrow: 1,
            overflow: "hidden"
        }}>
            {versions.slice(0, 5).map((version, idx) => {
                return <Box key={version.id}>
                    {idx > 0 && <Divider sx={{ my: 2 }}/>}
                    <ChangelogEntry
                        releaseType={idx === 0 ? ReleaseType.CURRENT : version.major ? ReleaseType.MAJOR : ReleaseType.MINOR}
                        versionCode={version.code}
                        title={t(`changelog:${version.id}.title`)}
                        description={t(`changelog:${version.id}.description`)}
                        tags={version.tags}
                        releaseDate={version.release}
                    />
                </Box>
            })}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap-reverse", alignItems: "center", mt: -1, mb: -1, flexGrow: 1, overflow: "hidden" }}>
            <Box display="flex" flexGrow={1} mt={1}>
                <Box ml={-1}>
                    <Link href="/changelogs">
                        <a><Button variant="text" color="primary">{t("changelog:readMore")}</Button></a>
                    </Link>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" mt={1} overflow="auto">
                <Typography variant="body2" component="p" color="textSecondary">{t("changelog:legend")}</Typography>
                {Object.values(TagType).map(tagType => <ChangelogTagsTag key={tagType} tagType={tagType}/>)}
            </Box>
        </Box>
    </Card>
}